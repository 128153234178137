(function() {
    'use strict';

    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false; 
    var resEnPantalla = 0;

    let heros = [];      

    // Obtener todos los enlaces del menú en un array
    const menuLinks = Array.from(document.querySelectorAll('.nav_links'));    

    window.onload = (event) => {

        iniciarClicks(); 

        // ! Escuchar el evento de desplazamiento y actualizar la clase 'nav_active'
        window.addEventListener('scroll', updateActiveClass);

        // ! Actualizar la clase 'nav_active' cuando la página se carga
        window.addEventListener('load', updateActiveClass);        

        //! LLENAR LOS BANNERS DE PUBLICIDAD
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) {
            llena_bannerspub(div_pub);             
        };

    };

    //! =================================================================================
    //* Función que activa el monitoreo de los clics y el scroll
    //! =================================================================================

    function iniciarClicks() {
            
        document.addEventListener('click', (e) => {
            e.preventDefault();
            clicksWeb(e.target);
        });

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(next_fondo, 3000); 
        }

    }

    //! =================================================================================
    //* Función que ejecuta los diferentes clicks de la página
    //! =================================================================================

    function clicksWeb(objetoDom) {

        // console.log(objetoDom);

        if (objetoDom.hasAttribute('idclick')) {

            const valorIdClick = objetoDom.getAttribute('idclick');
            const valorIdServicio = objetoDom.getAttribute('idservicio');

            fetch('../../assets/file_clicks.json')
            .then(response => response.json())
            .then(data => {
      
                let lista_clicks = data;

                // Buscar el registro con tipo="pagina" y clave="01"
                let reg_click = data.clicks.find(function(item) {
                    return item.idclick === valorIdClick;
                });

                let tipo_click = reg_click.tipo;
                switch ( tipo_click ) {

                    case "link":

                        let url = reg_click.href;

                        if (!url) {
                            url = objetoDom.href
                            console.log(url);
                        
                        }

                        if (valorIdServicio !== null) {
                            url += "?id=" + valorIdServicio
                        }

                        window.open(url,reg_click.ventana);
                        break;

                    case "function":

                        let idfunc = reg_click.idfunc;
                        let href = reg_click.href;

                        switch (idfunc) {

                            case "01":
                                moveToSection(href);
                                break;

                            case "02":
                                reproduce_video_youtube(objetoDom);
                                break;
                                                            
                            case "06":
                                toggleAccordion(objetoDom);
                                break;
                            
                            case "07":
                                menuLat = true;
                                apertura();
                                break;
    
                            default:
                                break;
                        }
                        break;    

                    default:
                        break;
                }

          
            })
            .catch(error => {
                console.log('Error al cargar el archivo JSON:', error);
            });
        }
        

    } 
    
    //! =================================================================================
    //* Función para llenar el slider de los banners de publicidad
    //! =================================================================================

    function llena_bannerspub(div_pub) { 

        let divIndicadores = document.getElementById('div_indicadores');

        div_pub.innerHTML = '';
        divIndicadores.innerHTML = '';

        fetch('../../assets/patros.json')
            .then(response => response.json())
            .then(data => {
                                    
                const anuncios = data.anuncios;
                let i = 0;

                anuncios.forEach(anuncio => {

                    // ! Creando los botones de los indicadores del carrusel
                    let button = document.createElement("button");
                    button.setAttribute("type", "button");
                    button.setAttribute("data-bs-target", "#crs_pub");
                    button.setAttribute("data-bs-slide-to", i.toString());                    

                    const link_anuncio = document.createElement('div');

                    link_anuncio.classList.add('carousel-item');
                    if (i===0) {
                        button.classList.add("active");                            
                        link_anuncio.classList.add('active');
                    }
                    i++;

                    link_anuncio.innerHTML = `

                        <picture>
                            <source srcset="build/img/publicidad/${anuncio.clave}.avif" type="image/avif">
                            <source srcset="build/img/publicidad/${anuncio.clave}.webp" type="image/webp">
                            <img src="build/img/publicidad/${anuncio.clave}.jpg" alt="Banner publicitario" class="d-block fto-img-pub" style="width:100%">
                        </picture>    

                        <div class="carousel-caption top-0 mt-4 d-flex align-items-center justify-content-center flex-column pub_content" style="max-height: 65vh;">
                            <div class="pub-logo">
                                <picture>
                                    <source srcset="build/img/publicidad/logo${anuncio.clave}.avif" type="image/avif">
                                    <source srcset="build/img/publicidad/logo${anuncio.clave}.webp" type="image/webp">
                                    <img width="350" height="150" src="build/img/publicidad/logo${anuncio.clave}.png" alt="Logotipo publicitario">
                                </picture>
                            </div>
                            
                            <div class="pub-textos">
                                <h3>${anuncio.titulo}</h3>
                                <p class="text-white">${anuncio.desc1}</p>
                                <button class="boton mt-5" idclick="${anuncio.idclick}">Más Información</button>                            
                            </div>
                        </div>
                    `;

                    // Agrega la anuncio al contenedor 
                    divIndicadores.appendChild(button);                       
                    div_pub.appendChild(link_anuncio);
                });
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      
    
    }

    //! =================================================================================
    //* Función para moverme automáticamente a una sección de la página
    //! =================================================================================
    
    function moveToSection(sectionId) {
        // Si se pasa "top" como sección, se desplaza al inicio de la página
        if (sectionId === 'top') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }
      
        // Busca el elemento con el ID proporcionado
        const element = document.getElementById(sectionId);
      
        // Si el elemento existe, se desplaza a él
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        } else {
            console.warn(`Elemento con ID ${sectionId} no encontrado.`);
        }
      }
      
    //! =================================================================================
    //* Función para abrir el menú lateral cuando el tamaño sea tablet o inferior
    //! =================================================================================

    function apertura() {
        let menu    = document.getElementById('navPrincipal');
        let botSubir = document.getElementById('botInicio');
        let botAccWP	 = document.getElementById('botWP');
        
        if (cerrado) {
            menu.style.width = '100vw!important';
            cerrado = false;
            
            if (botSubir) {
                botSubir.style.visibility = 'hidden';
                botAccWP.style.visibility = 'hidden';
            }

        } else {
            menu.style.width = '0%';
            menu.style.overflow = 'hidden';
            cerrado = true;
            menuLat = false;
            if (botSubir) {
                botSubir.style.visibility = 'visible';
                botAccWP.style.visibility = 'visible';
            }
        }
        menu.classList.toggle('menu2');
    };   

    //! =================================================================================
    //* Función para activar el efecto de las prefuntas frecuentes
    //! =================================================================================

    function toggleAccordion(elem) {

        let elemFaq;
        let i;

        if (elem.matches('.btnFaq') === false) {
            elemFaq = elem.parentNode;
        } else {
            elemFaq = elem;
        }

        const items = document.querySelectorAll(".accordion button");
        const itemToggle = elemFaq.getAttribute('aria-expanded');
        
        for (i = 0; i < items.length; i++) {
          items[i].setAttribute('aria-expanded', 'false');
        }
        
        if (itemToggle == 'false') {
          elemFaq.setAttribute('aria-expanded', 'true');
        }
    }
    
    //! =================================================================================
    //* Función para cambiar el color de fondo de la barra de menú según la posición de la pantalla
    //* Si está en el top, es transparente, si no se le pone un color obscuro
    //! =================================================================================

    function navBackground() {

        let nav = document.querySelector('.nav');
        let desp_actual = window.pageYOffset;
        let ancho_screen = window.innerWidth;        

        if ( desp_actual <= 300 ) {

            nav.style.borderBottom = ''; 

            if (ancho_screen > 768 ) {
                nav.style.background = 'rgba(0,0,0,0.5)';
            } else {
                nav.style.background = 'linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%)';
            }

        } else {
            nav.style.background = '#1F2022';
            nav.style.borderBottom = '2px solid #E33803';
        }
        nav.style.transition = '.5s';
    }

    function next_fondo() {
        let idxHero = actBanner(heros, 0);      
        nextBanner(heros, idxHero, '+');        
    }

    function actBanner(array, idxBan) {
        while (idxBan < array.length) {
            if (array[idxBan].classList.contains('banner_visible')) {
                break;
            }
            idxBan++;
        }
        return idxBan;
    }

    function nextBanner(array, idx, opr) {
        let idxNext = idx;
        if (opr==='+') {
            idxNext++;
            if (idxNext > array.length - 1) {
                idxNext = 0;
            }    
        } else {
            --idxNext;
            if (idxNext < 0) {
                idxNext = array.length - 1;
            }
        }
        array[idx].classList.remove('banner_visible');
        array[idxNext].classList.add('banner_visible');
    }


    //! =================================================================================
    //* Función para marcar las opciones del menú dependiendo de la posición 
    //* en la que se encuentre en pantalla
    //! =================================================================================

    function updateActiveClass() {
        let found = false;
    
        // Primero, quitar la clase 'nav_active' de todos los elementos
        menuLinks.forEach(link => link.parentElement.classList.remove('nav_active'));

        // Iterar a través de cada enlace del menú
        for (const link of menuLinks) {
            const sectionId = link.getAttribute('href').substring(1); // Eliminar el '#' del href
            const section = document.getElementById(sectionId);

            if (section) {
                const rect = section.getBoundingClientRect();

                // Comprobar si la sección está en la ventana de visualización
                if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {

                    link.parentElement.classList.add('nav_active');
                    found = true;

                } else {

                    link.parentElement.classList.remove('nav_active');

                }
            }
        }

        // Si ninguna sección coincide, establecer 'Inicio' como activo
        if (!found) {
            document.querySelector('[idclick="001"]').parentElement.classList.add('nav_active');
        }
    }

    //! =================================================================================
    //* Función para reproducir vídeos de Youtube directamente en la página
    //! =================================================================================

    function reproduce_video_youtube(obj) {

        const parentPadre = obj.parentNode.parentNode.parentNode;
        const iframeElement = parentPadre.querySelector(".youtube-video iframe");
        iframeElement.setAttribute("src", null );  // Autoplay y mute
 
        const parentElement = obj.parentNode.parentNode;
        parentElement.remove();
         
        iframeElement.setAttribute("src", "https://www.youtube.com/embed/"+obj.dataset.embed+"?autoplay=1" );  // Autoplay y mute
         
     }
 

})();